import {
  NameStepsEnum,
  OrgTypeEnum,
  NamesOfFieldEnum,
  FormData,
  ClientId,
  PayformTechDetailsFormFields,
  PayformDetailsFormFields,
  AccountDetailsFormFields,
  MarketplaceDetailsFormFields,
  AdditionalDetailsFormFields,
  SharedTypeRequestFields
} from "~/typings/types";
import { getValuesFromObject } from "~/utils/getValuesFromObject";
import { getWithPrefixPhone } from "~/utils/getWithPrefixPhone";
import { getIsWantToBecomePartner } from "~/utils/getIsWantToBecomePartner";
import {
  postDraftCompanyDetails,
  postDraftContactDetails,
  postDraftPayformDetails,
  postDraftAccountDetails,
  postDraftPayformTechDetails,
  postDraftMarketplaceDetails,
  postDraftAdditionalDetails,
  ReqPostArguments,
  ResPost
} from "./apiMethods";
import { CompanyDetailsFormFields, ContactDetailsFormFields } from "~/typings/types";
import { getFieldNameWithCondition } from "~/utils";
import { defaultAxiosRequestConfig } from "~/api/apiMethods";

type RequestFunction = ({
  data,
  config
}: ReqPostArguments<SharedTypeRequestFields>) => Promise<ResPost>;
type RequestConfig = Omit<Record<NameStepsEnum, RequestFunction>, NameStepsEnum.AttachmentsDetails>;

const requestConfig: RequestConfig = {
  [NameStepsEnum.CompanyDetails]: postDraftCompanyDetails,
  [NameStepsEnum.ContactDetails]: postDraftContactDetails,
  [NameStepsEnum.PayformDetails]: postDraftPayformDetails,
  [NameStepsEnum.AccountDetails]: postDraftAccountDetails,
  [NameStepsEnum.PayformTechDetails]: postDraftPayformTechDetails,
  [NameStepsEnum.MarketplaceDetails]: postDraftMarketplaceDetails,
  [NameStepsEnum.AdditionalDetails]: postDraftAdditionalDetails
};

interface ApiService {
  values: FormData;
  pageId: Exclude<NameStepsEnum, NameStepsEnum.AttachmentsDetails>;
  clientId: ClientId;
  orgType: OrgTypeEnum;
}

export const apiServiceCustomer = async ({ values, pageId, clientId, orgType }: ApiService) =>
  await new Promise((resolve, reject) => {
    let reqData: any; // TODO: fixed any type

    if (pageId === NameStepsEnum.CompanyDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          ...getValuesFromObject<Omit<CompanyDetailsFormFields, NamesOfFieldEnum.OrgType>>({
            object: values,
            values: [
              NamesOfFieldEnum.Name,
              NamesOfFieldEnum.BankName,
              NamesOfFieldEnum.SettlementAccount,
              NamesOfFieldEnum.CorrespondentAccount,
              NamesOfFieldEnum.BankBic,
              NamesOfFieldEnum.LegalAddress,
              NamesOfFieldEnum.LegalAddressFiasId,
              NamesOfFieldEnum.PostalAddress,
              NamesOfFieldEnum.PostalAddressFiasId,
              NamesOfFieldEnum.SignatureInfo,
              NamesOfFieldEnum.Inn,
              getFieldNameWithCondition(orgType === OrgTypeEnum.Company, NamesOfFieldEnum.ApprovalDocument),
              getFieldNameWithCondition(orgType === OrgTypeEnum.Company, NamesOfFieldEnum.Ogrn),
              getFieldNameWithCondition(orgType === OrgTypeEnum.Company, NamesOfFieldEnum.Kpp),
              getFieldNameWithCondition(orgType === OrgTypeEnum.Company, NamesOfFieldEnum.Vat),
              getFieldNameWithCondition(
                orgType === OrgTypeEnum.IndividualEntrepreneur,
                NamesOfFieldEnum.Ogrnip
              ),
              getFieldNameWithCondition(
                orgType === OrgTypeEnum.IndividualEntrepreneur,
                NamesOfFieldEnum.VatMode
              )
            ]
          })
        }
      };
    }

    if (pageId === NameStepsEnum.ContactDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          [NamesOfFieldEnum.LprPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.LprPhone]),
          [NamesOfFieldEnum.BuhPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.BuhPhone]),
          [NamesOfFieldEnum.TechPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.TechPhone]),
          ...getValuesFromObject<
            Omit<
              ContactDetailsFormFields,
              | NamesOfFieldEnum.OrgType
              | NamesOfFieldEnum.LprPhone
              | NamesOfFieldEnum.BuhPhone
              | NamesOfFieldEnum.TechPhone
            >
          >({
            object: values,
            values: [NamesOfFieldEnum.LprName, NamesOfFieldEnum.BuhName, NamesOfFieldEnum.TechName]
          })
        }
      };
    }

    if (pageId === NameStepsEnum.PayformDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          [NamesOfFieldEnum.PayformPhone]: getWithPrefixPhone(
            values?.[NamesOfFieldEnum.PayformPhone]
          ),
          ...getValuesFromObject<
            Omit<PayformDetailsFormFields, NamesOfFieldEnum.OrgType | NamesOfFieldEnum.PayformPhone>
          >({
            object: values,
            values: [NamesOfFieldEnum.PayformEmail, NamesOfFieldEnum.PayformLinks]
          })
        }
      };
    }

    if (pageId === NameStepsEnum.AccountDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          [NamesOfFieldEnum.CrmPhone]: getWithPrefixPhone(values?.[NamesOfFieldEnum.CrmPhone]),
          [NamesOfFieldEnum.IsWantToBecomePartner]: getIsWantToBecomePartner(
            values?.[NamesOfFieldEnum.IsWantToBecomePartner]
          ),
          ...getValuesFromObject<
            Omit<
              AccountDetailsFormFields,
              | NamesOfFieldEnum.OrgType
              | NamesOfFieldEnum.CrmPhone
              | NamesOfFieldEnum.IsWantToBecomePartner
            >
          >({
            object: values,
            values: [
              NamesOfFieldEnum.CrmName,
              NamesOfFieldEnum.CrmEmail,
              NamesOfFieldEnum.CrmSocialLinks
            ]
          })
        }
      };
    }

    if (pageId === NameStepsEnum.PayformTechDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          [NamesOfFieldEnum.PayformName]: `https://${values?.payform_name}.payform.ru`,
          ...getValuesFromObject<
            Omit<
              PayformTechDetailsFormFields,
              NamesOfFieldEnum.OrgType | NamesOfFieldEnum.PayformName
            >
          >({
            object: values,
            values: [
              NamesOfFieldEnum.PaymentNotificationEmail,
              NamesOfFieldEnum.ProductType,
              NamesOfFieldEnum.ProductName,
              NamesOfFieldEnum.PayformPaymentMethods,
              getFieldNameWithCondition(
                orgType === OrgTypeEnum.IndividualEntrepreneur || orgType === OrgTypeEnum.Company,
                NamesOfFieldEnum.PaymentMethodProdamusSubscription
              )
            ]
          })
        }
      };

      if (values[NamesOfFieldEnum.ProductType] === "education") {
        reqData.fields[NamesOfFieldEnum.IsHaveEducationalLicense] =
          values[NamesOfFieldEnum.IsHaveEducationalLicense];
      }
    }

    if (pageId === NameStepsEnum.MarketplaceDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          ...getValuesFromObject<Omit<MarketplaceDetailsFormFields, NamesOfFieldEnum.OrgType>>({
            object: values,
            values: [NamesOfFieldEnum.ShopName, NamesOfFieldEnum.ProductDescription]
          })
        }
      };
    }

    if (pageId === NameStepsEnum.AdditionalDetails) {
      reqData = {
        id: clientId,
        fields: {
          [NamesOfFieldEnum.OrgType]: orgType,
          ...getValuesFromObject<Omit<AdditionalDetailsFormFields, NamesOfFieldEnum.OrgType>>({
            object: values,
            values: [
              NamesOfFieldEnum.ClientFrom,
              NamesOfFieldEnum.ClientPromocode,
              NamesOfFieldEnum.ClientLengthOfService
            ]
          })
        }
      };
    }

    requestConfig?.[pageId]({ data: reqData, config: defaultAxiosRequestConfig })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        console.log("err requestConfig", err);
        reject(err);
      });
  });
